var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-row gap-2" }, [
    _c(
      "div",
      { staticClass: "fixAutoComplete flex-grow" },
      [
        _c("select-suggestion", {
          attrs: {
            column: "name,email",
            model: "User",
            label: _vm.$t("usuario"),
            appendClearOption: true,
            itemTextFormat: (model) =>
              `${model.name} <br/><div class=\'text-sm\'>${model.email}<div>`,
            placeholderText: "Digite o nome ou e-mail do usuário",
          },
          model: {
            value: _vm.selectedUser,
            callback: function ($$v) {
              _vm.selectedUser = $$v
            },
            expression: "selectedUser",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex-grow-0 self-end" },
      [
        _c(
          "vs-button",
          {
            staticClass: "footer-button",
            attrs: { type: "filled", disabled: !_vm.selectedUser },
            on: { click: _vm.emitSelection },
          },
          [_vm._v(" " + _vm._s(_vm.$t("adicionar")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }